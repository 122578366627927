import React, { useEffect, useLayoutEffect, useRef } from "react"
import { Helmet } from "react-helmet"
import Winwheel from "winwheel"
import { useStaticQuery, graphql, withPrefix } from "gatsby"
import { useMediaPredicate } from "react-media-hook"
import BackgroundImage from "gatsby-background-image"
import gql from "graphql-tag"
import { useMutation } from "@apollo/react-hooks"

import "../../../graphql/fragments"

import SEO from "../../../components/seo"
import Layout from "../../../components/layout"
import { Section, Container } from "../../../components/grid/grid"

import WheelPng from "../../../assets/img/wheels/enchante-spinner.png"
import spinButtonPng from "../../../assets/img/wheels/apartemen-spin-btn.png"
import pointerPng from "../../../assets/img/wheels/enchante-arrow.png"
import tickSound from "../../../assets/video/tick.mp3"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"
const axios = require("axios")

const SUBMIT_SPIN = gql`
  mutation SubmitFormSpinWheelEnchante(
    $fullName: String
    $email: String
    $phoneNumber: String
    $product: String
    $discount: String
    $salesName: String
  ) {
    submit2SpinWheelEnchante(
      input: {
        clientMutationId: ""
        fullName: $fullName
        email: $email
        phoneNumber: $phoneNumber
        product: $product
        discount: $discount
        salesName: $salesName
      }
    ) {
      status
      message
    }
  }
`
const ThrSpinPage = location => {
  const staticData = useStaticQuery(graphql`
    query {
      imgMobileProperty: file(
        relativePath: { eq: "wheels/enchante-spin-wheel.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1125) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imgDesktopProperty: file(
        relativePath: { eq: "wheels/enchante-spin-wheel.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1125) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const resizeScreen = useMediaPredicate("(max-width: 992px)")
  const topSpinner = useRef(null)
  const botSpinner = useRef(null)
  const canvas = useRef(null)

  var styles = {
    pointer: {
      "@media (max-width: 320.98px)": {
        position: "absolute",
        top: "38%",
        right: "12%",
        width: "35px",
        transform: "rotate(90deg)",
      },
      "@media (min-width: 321px) and (max-width: 374.98px)": {
        position: "absolute",
        top: "38%",
        right: "12%",
        zIndex: "999",
        width: "10%",
        transform: "rotate(90deg)",
      },
      "@media (min-width: 375px) and (max-width: 383.98px) and (min-height: 321px) and (max-height: 667.98px)": {
        position: "absolute",
        top: "47%",
        right: "15%",
        zIndex: "999",
        width: "8%",
        transform: "rotate(90deg)",
      },
      "@media (min-width: 375px) and (max-width: 383.98px) and (min-height: 668px) and (max-height: 812.98px)": {
        position: "absolute",
        top: "37%",
        right: "14%",
        zIndex: "999",
        width: "8%",
        transform: "rotate(90deg)",
      },
      "@media (min-width: 384px) and (max-width: 413.98px)": {
        position: "absolute",
        top: "40%",
        right: "12%",
        zIndex: "999",
        width: "10%",
        transform: "rotate(90deg)",
      },
      "@media (min-width: 414px) and (max-width: 575.98px) and (max-height: 896.98px)": {
        position: "absolute",
        top: "32%",
        right: "13%",
        zIndex: "999",
        width: "10%",
        transform: "rotate(90deg)",
      },
      "@media (min-width: 576px) and (max-width: 767.98px)": {
        position: "absolute",
        top: "44%",
        right: "14%",
        zIndex: "999",
        width: "8%",
        transform: "rotate(90deg)",
      },
      "@media (min-width: 768px) and (max-width: 991.98px)": {
        position: "absolute",
        top: "37%",
        right: "25%",
        zIndex: "999",
        width: "7%",
        transform: "rotate(90deg)",
      },
      "@media (min-width: 992px) and (max-width: 1199.98px)": {
        position: "absolute",
        top: "calc(100% - 67%)",
        right: "calc(100% - 52.6%)",
        zIndex: "999",
        width: "5%",
      },
      "@media (min-width: 1200px)": {
        position: "absolute",
        top: "49%",
        right: "32%",
        zIndex: "999",
        width: "80px",
        transform: "rotate(90deg)",
      },
      "@media (min-width: 1440px) and (max-width: 1730.98px) and (max-height: 990.98px)": {
        position: "absolute",
        top: "49%",
        right: "32%",
        zIndex: "999",
        width: "80px",
        transform: "rotate(90deg)",
      },
      "@media (min-width: 1920px) and (max-width: 2099.98px) and (max-height: 1080.98px)": {
        position: "absolute",
        top: "49%",
        right: "32%",
        zIndex: "999",
        width: "80px",
        transform: "rotate(90deg)",
      },
    },
    logo: {
      "@media (max-width: 320.98px)": {
        position: "absolute",
        top: "calc(100% - 73%)",
        right: "calc(100% - 63%)",
        width: "84px",
      },
      "@media (min-width: 321px) and (max-width: 374.98px)": {
        position: "absolute",
        top: "calc(100% - 70%)",
        right: "calc(100% - 65%)",
        zIndex: "999",
        width: "30%",
      },
      "@media (min-width: 375px) and (max-width: 383.98px) and (min-height: 321px) and (max-height: 667.98px)": {
        position: "absolute",
        top: "calc(100% - 64%)",
        right: "calc(100% - 65%)",
        zIndex: "999",
        width: "30%",
      },
      "@media (min-width: 375px) and (max-width: 383.98px) and (min-height: 668px) and (max-height: 812.98px)": {
        position: "absolute",
        top: "calc(100% - 73%)",
        right: "calc(100% - 65%)",
        zIndex: "999",
        width: "30%",
      },
      "@media (min-width: 384px) and (max-width: 413.98px)": {
        position: "absolute",
        top: "calc(100% - 63%)",
        right: "calc(100% - 68%)",
        zIndex: "999",
        width: "35%",
      },
      "@media (min-width: 414px) and (max-width: 575.98px) and (max-height: 896.98px)": {
        position: "absolute",
        top: "calc(100% - 68%)",
        right: "calc(100% - 65%)",
        zIndex: "999",
        width: "30%",
      },
      "@media (min-width: 576px) and (max-width: 767.98px)": {
        position: "absolute",
        top: "calc(100% - 49%)",
        right: "calc(100% - 63%)",
        zIndex: "999",
        width: "25%",
      },
      "@media (min-width: 768px) and (max-width: 991.98px)": {
        position: "absolute",
        top: "calc(100% - 57%)",
        right: "calc(100% - 60%)",
        zIndex: "999",
        width: "20%",
      },
      "@media (min-width: 992px) and (max-width: 1199.98px)": {
        position: "absolute",
        top: "calc(100% - 53%)",
        right: "calc(100% - 57%)",
        zIndex: "999",
        width: "14%",
      },
      "@media (min-width: 1200px)": {
        position: "absolute",
        top: "calc(100% - 49%)",
        right: "calc(100% - 58%)",
        zIndex: "999",
        width: "16%",
      },
      "@media (min-width: 1440px) and (max-width: 1730.98px) and (max-height: 990.98px)": {
        position: "absolute",
        top: "calc(100% - 48%)",
        right: "calc(100% - 58%)",
        zIndex: "999",
        width: "16%",
      },
      "@media (min-width: 1920px) and (max-width: 2099.98px) and (max-height: 1080.98px)": {
        position: "absolute",
        top: "calc(100% - 52%)",
        right: "calc(100% - 56%)",
        zIndex: "999",
        width: "12%",
      },
    },
  }

  let enchanteSegment = [
    { fillStyle: "transparent", text: "2%", textFillStyle: "transparent" },
    { fillStyle: "transparent", text: "4%", textFillStyle: "transparent" },
    { fillStyle: "transparent", text: "2.5%", textFillStyle: "transparent" },
    { fillStyle: "transparent", text: "3.5%", textFillStyle: "transparent" },
    { fillStyle: "transparent", text: "3%", textFillStyle: "transparent" },
    { fillStyle: "transparent", text: "2.5%", textFillStyle: "transparent" },
  ]

  const [submitSpin] = useMutation(SUBMIT_SPIN)

  useLayoutEffect(() => {
    ;(function loadWinWheel() {
      var script = document.createElement("script")
      script.src = withPrefix("Winwheel.js")
      document.body.appendChild(script)
    })()
    // ;(function loadWheelSegment() {
    //   var script = document.createElement("script")
    //   script.src = withPrefix("WheelSegment.js")
    //   document.body.appendChild(script)
    // })()
    ;(function loadTween() {
      var script = document.createElement("script")
      script.src = withPrefix("TweenMax.js")
      document.body.appendChild(script)
    })()
  }, [])

  useLayoutEffect(() => {
    // Vars used by the code in this page to do power controls.
    let wheelPower = 0
    let wheelSpinning = false
    let theWheel = new Winwheel({
      canvasId: "canvas",
      outerRadius: 206, // Set outer radius so wheel fits inside the background.
      responsive: true,
      centerX: 250, // Set x and y as number.
      centerY: 250,
      lineWidth: 0,
      innerRadius: 60, // Make wheel hollow so segments don't go all way to center.
      textFontSize: 24, // Set default font size for the segments.
      textOrientation: "horizontal", // Make text vertial so goes down from the outside of wheel.
      textAlignment: "center", // Align text to outside of wheel.
      textMargin: 15,
      numSegments: enchanteSegment.length, // Specify number of segments.
      segments: enchanteSegment,
      drawMode: "image",
      rotationAngle: 0,
      drawText: true,
      pointerAngle: 90,
      // Specify the animation to use.
      animation: {
        type: "spinToStop",
        duration: 10, // Duration in seconds.
        spins: 3, // Default number of complete spins.
        callbackFinished: alertPrize,
        callbackSound: playSound, // Function to call when the tick sound is to be triggered.
        soundTrigger: "pin", // Specify pins are to trigger the sound, the other option is 'segment'.
      },
      // Turn pins on.
      pins: {
        number: 24,
        fillStyle: "#fff",
        outerRadius: 0,
        responsive: true,
      },
    })

    // -------------------------------------------------------
    // LOAD IMAGE
    // -------------------------------------------------------
    let loadedImg = new Image()

    // Create callback to execute once the image has finished loading.
    loadedImg.onload = function() {
      theWheel.wheelImage = loadedImg // Make wheelImage equal the loaded image object.
      theWheel.draw() // Also call draw function to render the wheel.
    }

    // Set the image source, once complete this will trigger the onLoad callback (above).
    loadedImg.src = WheelPng

    // Loads the tick audio sound in to an audio object.
    let audio = new Audio(tickSound)

    // This function is called when the sound is to be played.
    function playSound() {
      // Stop and rewind the sound if it already happens to be playing.
      audio.pause()
      audio.currentTime = 0

      // Play the sound.
      audio.play()
    }

    // // Vars used by the code in this page to do power controls.
    // let wheelPower    = 0;
    // let wheelSpinning = false;

    // -------------------------------------------------------
    // Click handler for spin button.
    // -------------------------------------------------------
    // function startSpin(){
    //     // Ensure that spinning can't be clicked again while already running.
    //     if (wheelSpinning == false) {
    //         // Based on the power level selected adjust the number of spins for the wheel, the more times is has
    //         // to rotate with the duration of the animation the quicker the wheel spins.
    //         if (wheelPower == 1) {
    //             theWheel.animation.spins = 3;
    //         } else if (wheelPower == 2) {
    //             theWheel.animation.spins = 6;
    //         } else if (wheelPower == 3) {
    //             theWheel.animation.spins = 10;
    //         }

    //         // Disable the spin button so can't click again while wheel is spinning.
    //         document.getElementById('spin_button').src       = spinButtonPng;
    //         document.getElementById('spin_button').className = "";

    //         // Begin the spin animation by calling startAnimation on the wheel object.
    //         theWheel.startAnimation();

    //         // Set to true so that power can't be changed and spin button re-enabled during
    //         // the current animation. The user will have to reset before spinning again.
    //         wheelSpinning = true;
    //     }
    // }

    // -------------------------------------------------------
    // Function for reset button.
    // -------------------------------------------------------
    // function resetWheel(){
    //     theWheel.stopAnimation(false);  // Stop the animation, false as param so does not call callback function.
    //     theWheel.rotationAngle = 0;     // Re-set the wheel angle to 0 degrees.
    //     theWheel.draw();                // Call draw to render changes to the wheel.

    //     wheelSpinning = false;          // Reset to false to power buttons and spin can be clicked again.
    // }

    function sendSms(param) {
      /* const url = process.env.BASE_URL + "/api/sms/spin-apartemen" */
      const url = "https://www.sinarmasland.com/api/sms/spin-apartemen"
      var data = JSON.stringify({
        name: param.name,
        phoneNumber: param.phone,
        discount: param.discount,
      })
      const config = {
        method: "POST",
        url: url,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        data: data,
      }

      axios(config)
        .then(function(response) {
          console.log(JSON.stringify(response.data))
        })
        .catch(function(error) {
          console.log(error)
        })
    }

    function alertPrize(indicatedSegment) {
      console.log(indicatedSegment)
      const name = sessionStorage.getItem("customerName")
      const email = sessionStorage.getItem("customerEmail")
      const phone = sessionStorage.getItem("customerPhone")
      const product = sessionStorage.getItem("product")
      const salesName = sessionStorage.getItem("salesName")
      const discount = indicatedSegment.text

      const formatNumber = phone.split("+").join("")

      submitSpin({
        variables: {
          fullName: name,
          email: email,
          phoneNumber: formatNumber,
          product: product,
          salesName: salesName,
          discount: discount,
        },
      })
        .then(res => {
          console.log(res)

          let msg = ""
          if (
            sessionStorage.getItem("customerName") ||
            sessionStorage.getItem("customerEmail") ||
            sessionStorage.getItem("customerPhone") ||
            sessionStorage.getItem("product") ||
            sessionStorage.getItem("salesName")
          ) {
            msg = `Selamat Bapak/Ibu ${name}! Anda beruntung mendapatkan diskon tambahan ${discount} dari Program Spin Wheel Enchante 2024.`

            sendSms({
              name,
              phone,
              discount,
            })
            sessionStorage.removeItem("customerName")
            sessionStorage.removeItem("customerEmail")
            sessionStorage.removeItem("customerPhone")
            sessionStorage.removeItem("product")
            sessionStorage.removeItem("salesName")

            alert(msg)
          }
          window.location.href = "/enchante-add"
          // navigate("/enchante-add")
        })
        .catch(e => console.log(e))
    }

    // function closeModal(){
    //     var popup = document.getElementById("myModal");
    //     document.getElementById('spin_button').src= spinButtonPng;
    //     resetWheel();
    //     popup.classList.remove("in");

    //     navigate('/thrlebaran/thankyou')
    //     localStorage.clear();
    // }

    function startSpin() {
      // Ensure that spinning can't be clicked again while already running.
      if (wheelSpinning == false) {
        // Based on the power level selected adjust the number of spins for the wheel, the more times is has
        // to rotate with the duration of the animation the quicker the wheel spins.
        if (wheelPower == 1) {
          theWheel.animation.spins = 3
        } else if (wheelPower == 2) {
          theWheel.animation.spins = 6
        } else if (wheelPower == 3) {
          theWheel.animation.spins = 10
        }

        // Disable the spin button so can't click again while wheel is spinning.
        document.getElementById("spinner").src = spinButtonPng
        document.getElementById("spinner").className = ""
        // Begin the spin animation by calling startAnimation on the wheel object.
        // Set to true so that power can't be changed and spin button re-enabled during
        // the current animation. The user will have to reset before spinning again.
        if (
          sessionStorage.getItem("customerName") &&
          sessionStorage.getItem("customerEmail") &&
          sessionStorage.getItem("customerPhone") &&
          sessionStorage.getItem("product")
        ) {
          theWheel.startAnimation()
        } else {
          wheelSpinning = true
        }
        // if (sessionStorage.getItem("jumlahVoucher")) {
        //   if (!sessionStorage.getItem("countVoucher")) {
        //     sessionStorage.setItem(
        //       "countVoucher",
        //       sessionStorage.getItem("jumlahVoucher")
        //     )
        //   }
        //   let countVoucher = sessionStorage.getItem("countVoucher")
        //   if (countVoucher > 0) {
        //     sessionStorage.setItem("countVoucher", countVoucher - 1)
        //     theWheel.startAnimation()
        //   } else {
        //     sessionStorage.removeItem("customerName")
        //     sessionStorage.removeItem("customerEmail")
        //     sessionStorage.removeItem("customerPhone")
        //     sessionStorage.removeItem("product")
        //     wheelSpinning = true
        //   }
        // }
      }
    }

    // trigger spinner
    topSpinner.current.onclick = () => startSpin()
    botSpinner.current.onclick = () => startSpin()
    canvas.current.onclick = () => startSpin()
  }, [])

  useEffect(() => {
    if (location.uri == "/enchante-add/spin") {
      // document.getElementById("navbarDesktop").classList.add("d-none")
      // document.getElementById("navbarMobile").classList.add("d-none")
      let navbarDesktop = document
        .getElementById("desktopNav")
        .querySelectorAll(".nav-link")
      document.getElementsByClassName("animated")[0].classList.add("mt-0")
      document.getElementsByClassName("footer-top")[0].classList.add("d-none")
      document
        .getElementsByClassName("footer-scroll")[0]
        .classList.add("d-none")
      document
        .getElementsByClassName("footer-bottom")[0]
        .classList.add("d-none")
      document
        .getElementsByClassName("navbar-nav-main")[0]
        .classList.add("d-none")

      document.querySelector(
        "footer .red-ribbon .ribbon.black"
      ).style.background = "#fff"

      document.querySelector(
        "footer .red-ribbon .ribbon.red"
      ).style.background =
        "linear-gradient(to right, #ab0203, #f00000) !important"

      if (resizeScreen) {
        document.getElementsByClassName(
          "logo-secondary"
        )[1].style.display = `none`
        document.getElementsByClassName("logo-main")[1].style.display = `block`
      } else {
        document.getElementsByClassName(
          "logo-secondary"
        )[0].style.display = `none`
        document.getElementsByClassName("logo-main")[0].style.display = `block`
      }

      for (let i = 0; i < navbarDesktop.length; i++) {
        navbarDesktop[i].style.color = "#000"
      }
      document.querySelector("#lang-wrapper").classList.remove("d-flex")
      document.querySelector("#lang-wrapper").classList.add("d-none")
    }
  }, [])

  return (
    <Layout location={location} currentLocation={location.uri}>
      <SEO
        title={`Spin to Wheel Enchante 2024`}
        fbTitle={`Spin to Wheel Enchante 2024`}
        twitterTitle={`Spin to Wheel Enchante 2024`}
        description={`Dapatkan Additional discount enchante dengan sistem spin wheel dengan range 2% sampai dengan 4%.`}
        fbDescription={`Dapatkan Additional discount enchante dengan sistem spin wheel dengan range 2% sampai dengan 4%.`}
        twitterDescription={`Dapatkan Additional discount enchante dengan sistem spin wheel dengan range 2% sampai dengan 4%.`}
        img={staticData.imgDesktopProperty.childImageSharp.fluid}
        fbImg={staticData.imgDesktopProperty.childImageSharp.fluid}
        twitterImg={staticData.imgDesktopProperty.childImageSharp.fluid}
      />
      <Helmet>
        <body className="bd-page" />
      </Helmet>
      <Section className="mainSpinApartemen mainSpinEnchante p-0">
        {/* background page */}
        <BackgroundImage
          Tag="div"
          fluid={
            resizeScreen
              ? staticData.imgMobileProperty.childImageSharp.fluid
              : staticData.imgDesktopProperty.childImageSharp.fluid
          }
          className="bgSpin"
        >
          <Container className="mainPaper">
            <Section className="topSpin p-0">
              <Section className="mobileSpin p-0 text-center">
                <img
                  ref={topSpinner}
                  id="spinner"
                  src={spinButtonPng}
                  //width="150"
                  //onClick={() => startSpin()}
                />
              </Section>
            </Section>
            <Section className="mainSpin p-0">
              <div className="wrapper-canvas">
                <canvas
                  ref={canvas}
                  id="canvas"
                  width="500"
                  height="500"
                  //onClick={startSpin}
                  data-responsiveminwidth="40"
                  data-responsivescaleheight="true"
                  data-responsivemargin="50"
                >
                  <p style={{ color: "white" }} align="center">
                    Sorry, your browser doesn't support canvas. Please try
                    another.
                  </p>
                </canvas>
                <img
                  className="pointer"
                  src={pointerPng}
                  id="pointerWheel"
                  /* style={styles.pointer} */
                />
              </div>
            </Section>
            <Section className="bottomSpin p-0">
              <Section className="buttonSpin p-0 text-right">
                <img
                  ref={botSpinner}
                  id="spinner"
                  src={spinButtonPng}
                  //onClick={() => startSpin()}
                />
              </Section>
            </Section>
          </Container>
        </BackgroundImage>
      </Section>
    </Layout>
  )
}
export default ThrSpinPage
